import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import MenuItem from "./MenuItem.js";

const OrderForm = ({ handleChange }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  function handleClick(item, counter) {
    handleChange(item, counter);
  }

  const getMenuItems = async () => {
    setLoading(true);
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/items`);
    const data = await response.json();
    setMenuItems(data);
    setLoading(false);
    setLoaded(true);
  };

  useEffect(() => {
    getMenuItems();
  }, []);

  return (
    <>
      {loaded && (
        <Container fluid style={{ marginTop: "160px" }}>
          <Row>
            {menuItems.map((item) => (
              <Col xs={12} md={6} lg={4} xxl={3} key={item.id}>
                <MenuItem item={item} onItemChange={handleClick}></MenuItem>
              </Col>
            ))}
          </Row>
        </Container>
      )}

      {loading && (
        <Spinner className="spinner" animation="border" role="status">
          <span className="visually-hidden">Laddar...</span>
        </Spinner>
      )}
    </>
  );
};

export default OrderForm;
